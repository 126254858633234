module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Decenter","description":"Decenter is an R&D center and project incubator focused primarily on building on the Ethereum platform. We build blockchain solutions and decentralized applications using Ethereum and P2P storage solutions.","icon":"static/favicon-32x32.png","icons":[{"src":"static/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"static/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"static/apple-touch-icon.png","sizes":"180x180","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"26a655a1187b9e3a2301c5c03c8919b2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"2","matomoUrl":"https://cryptage.innocraft.cloud","siteUrl":"https://www.decenter.com/","matomoPhpScript":"matomo.php","matomoJsScript":"matomo.js","localScript":"//cdn.innocraft.cloud/cryptage.innocraft.cloud/matomo.js"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
